$max-body-size: 550px;
$max-left-spacing: 60px;
$small-left-spacing: $max-left-spacing / 2;
$add-button-width: 50px;
$ei-input-width: 61px;
$todo-input-spacing: 16px;
$todo-main-spacing: $todo-input-spacing - 1;
$main-font-size: 24px;
$small-font-size: 20px;
$remove-button-width: 40px;
$remove-button-width-extras: 10px;
$todo-metas-width: 64px;
$sabrina: rgba(45, 20, 200, 0.25);

h1 {
  font-size: 100px;
  font-weight: 100;
  text-align: center;
  color: $sabrina;
  text-rendering: optimizeLegibility;
}

:focus {
  outline: 0;
}

::placeholder {
  font-style: italic;
  font-weight: 300;
  color: #a3a3a3;
}

.notepad {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
  0 25px 50px 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto 50px auto;
  min-width: 230px;

  form {
    position: relative;
    background: rgba(0, 0, 0, 0.003);
    box-shadow: inset 0 -2px 1px rgba(0,0,0,0.03);

    .text-input-wrapper {
      $right-spacing: $todo-input-spacing + $add-button-width + $ei-input-width;
      padding: $todo-input-spacing $right-spacing $todo-input-spacing $max-left-spacing;

      @media only screen and (max-width: 600px),
      only screen and (min-width: 850px) and (max-width: 990px) {
        padding: $todo-input-spacing $right-spacing $todo-input-spacing $small-left-spacing;

        input[type="text"] {
          font-size: $small-font-size;
          height: $small-font-size + 10px;
        }
      }

      input {
        height: $main-font-size + 10px;
        width: 100%;
        font-size: $main-font-size;
        font-family: inherit;
        font-weight: inherit;
        line-height: 1.4em;
        border: 0;
        color: inherit;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    .ei-wrapper input {
      box-sizing: border-box;
      position: absolute;
      right: $add-button-width;
      height: 33px;
      border: 1px solid lightgrey;
      width: $ei-input-width;
      padding: 0 2px 0 8px;
      font-family: inherit;
      font-weight: inherit;
      border-top: 0;
      border-right: 0;

      @media only screen and (max-width: 600px),
      only screen and (min-width: 850px) and (max-width: 990px) {
        height: 31px;
      }

      &.effort {
        top: 0;
      }

      &.impact {
        bottom: 0;
        border-bottom: 0;
      }
    }

    button {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: $add-button-width;
    }

    // removes FF red validation styling after first submit
    :-moz-ui-invalid {
      box-shadow: none;
    }
  }

  .prioritise-button-wrapper {
    height: 63px;
    transition: height 400ms ease-in-out;
    overflow: hidden;

    &.hidden {
      height: 0;
    }

    .prioritise-button {
      border: none;
      background: lighten(#4d4d4d, 30%);
      color: #ffffff;
      font-family: inherit;
      font-size: $main-font-size;
      cursor: pointer;
      transition: transform 150ms ease;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 100%;
      width: 100%;

      &:active {
        transform: scale(0.99);
      }
    }
  }

  .list {
    position: relative;
    border-top: 1px solid #e6e6e6;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 50px;
      overflow: hidden;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
      0 8px 0 -3px #f6f6f6,
      0 9px 1px -3px rgba(0, 0, 0, 0.2),
      0 16px 0 -6px #f6f6f6,
      0 17px 2px -6px rgba(0, 0, 0, 0.2);
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        position: relative;
        font-size: $main-font-size;
        border-bottom: 1px solid #ededed;
        word-break: break-all;
        padding: $todo-main-spacing $todo-main-spacing $todo-main-spacing $max-left-spacing;
        line-height: 1.2;
        transition: color 0.4s;

        @media only screen and (max-width: 600px),
        only screen and (min-width: 850px) and (max-width: 990px) {
          padding: $todo-main-spacing $todo-main-spacing $todo-main-spacing $small-left-spacing;
        }

        .remove {
          display: block;
          position: absolute;
          top: 0;
          right: 10px;
          bottom: 0;
          width: $remove-button-width;
          height: 40px;
          margin: auto 0 auto 11px;
          font-size: 30px;
          color: #cc9a9a;
          transition: color 0.2s ease-out;
          cursor: pointer;
          padding: 0;
          border: 0;
          background: none;
          vertical-align: baseline;
          font-family: inherit;
          font-weight: inherit;
          -webkit-appearance: none;
          appearance: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          &:hover {
            color: #af5b5e;
          }

          &:after {
            content: '×';
          }

          @media (hover: hover) and (pointer: fine) {
            display: none;
          }
        }

        &:hover .remove {
          display: block;
        }

        .todo-text {
          padding: 0 ($todo-metas-width + $remove-button-width + $remove-button-width-extras) 0 0;

          @media only screen and (max-width: 600px),
          only screen and (min-width: 850px) and (max-width: 990px) {
            font-size: $small-font-size;
          }
        }

        .todo-meta {
          position: absolute;
          top: 0;
          right: 50px;
          bottom: 0;
          width: $todo-metas-width;
          height: 40px;
          margin: auto 0 auto 11px;
          padding-top: 22px;

          .metaline {
            font-size: $main-font-size / 2;
            line-height: 0.6;
            padding: 2px;

            .metalinelabel,
            .metalinenumber {
              display: inline-block;
            }

            .metalinelabel {
              margin-right: 2px;
              text-align: right;
              width: 44px;
            }
          }
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.container {
  display: flow-root;
}

.graph {
  margin: 0 auto 25px auto;

  @media only screen and (min-width: 850px) {
    vertical-align:top;
    float: right;
  }
}

.notepad,
.graph {
  max-width: 550px;

  @media only screen and (min-width: 850px) {
    display: inline-block;
    margin: 0 10px 50px 10px;
    width: 45%;
  }
}

footer {
  margin-bottom: 20px;
  text-align: center;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.togglable-section {
  margin-bottom: 10px;

  button.show-hide-toggle {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    color: inherit;
    text-decoration: none;

    &:hover{
      text-decoration:underline;
    }
  }
}

.togglable-section-content {
  display: block;
  margin: 5px auto;
  transition: height 400ms ease-in-out;
  overflow: hidden;
  &.hidden {
    height: 0;
  }
}

.github-import,
.jira-import {
  height: 22px;
}

.settings {
  height: 104px;

  form {
    padding-bottom: 5px;

    .form-row {
      margin: 5px 0;

      label {
        margin: 0 5px;
      }
    }
  }
}

button {
  background-color: $sabrina;
}
